import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { EventBus } from "@/event-bus";
import {
  DuplicateOrder,
  ReconciliationWithEmbeddedOrder
} from "@/interfaces/retailSalesReconciliation";
import { ReconciliationOrderFields } from "@/metadata/order";
import {
  BaseOrderDuplicateTableHeaders,
  DuplicatedsTableHeaders
} from "@/metadata/retailSalesReconciliation";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { reconciliationService } from "@/services/Reconciliation.service";
import { retailSalesReconciliationService } from "@/services/retailSalesReconciliation.service";
import { PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableComponent,
  TableHeader,
  TableItem,
  TablePagination,
  TableSelectEvent
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PossibleDuplicates.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableProductsComponent
  }
})
export default class PossibleDuplicatesComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Getter("data", { namespace: "SaleReconciliationModule" })
  public data!: ReconciliationWithEmbeddedOrder;

  @Prop({ default: false }) public onDisplay!: boolean;

  public generalActions: ActionSubheader[] = reconciliationService.getGeneralActions(
    this.saveReconciliation,
    this.cancel
  );

  public duplicatesIDs: number[] = [];
  public items: TableItem[] = [];
  public headers = BaseOrderDuplicateTableHeaders;
  public duplicatedsHeaders = DuplicatedsTableHeaders;
  public fieldsToShow = ReconciliationOrderFields;
  public loading = true;
  public sale: TableItem[] = [];
  public possibleDuplicates: DuplicateOrder[] = [];
  public tableColors: string[] = [
    "#f2f2f2",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  public selectedItems: number[] = [];
  public tablePagination = {
    itemsPerPage: 10,
    totalItems: 0,
    currentPage: 1,
    itemsPerPageOptions: [10, 20, 50, 100],
    from: 1,
    to: 10
  };
  public pagination: TablePagination | null = null;

  public onSelection(event: TableSelectEvent) {
    this.selectedItems = [event.eventItem!.item.id];
  }

  public filter(header: TableHeader) {
    const h = header;

    const sort = `${!h.descending ? "-" : ""}${h.value || ""}`;
    this.loadDuplicates(sort);
  }

  public async saveReconciliation() {
    if (!this.selectedItems.length) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        String(i18n.t("reconciliation.need_to_check_one")),
        "warning"
      );
    } else {
      this.showLoadingWindow();
      this.duplicatesIDs = this.duplicatesIDs.filter(
        id => id !== this.selectedItems[0]
      );
      const serverResponse = await retailSalesReconciliationService.voidDuplicates(
        this.selectedItems[0],
        this.duplicatesIDs
      );
      if (serverResponse.success) {
        messagesService.showMessage(
          "fas fa-check",
          String(i18n.t("reconciliation.success_sync")),
          "success"
        );
        this.$emit("duplicateOrdersReconcilied", this.data);
        this.$router.push({
          name: "retail-sales-reco",
          params: { reload: "true" }
        });
      } else {
        messagesService.showMessage(
          "fas fa-exclamation-triangle",
          String(i18n.t("reconciliation.error")),
          "error"
        );
      }
      this.hideLoadingWindow();
    }
  }

  public cancel() {
    this.$router.push({ name: "retail-sales-reco" });
  }

  /**
   * Changes pagination : Responsible for switching to another page
   * @param pagination
   */
  protected changePagination(pagination: TablePagination) {
    this.loading = true;
    this.items = this.possibleDuplicates.slice(
      (pagination.currentPage - 1) * pagination.itemsPerPage,
      (pagination.currentPage - 1) * pagination.itemsPerPage +
        pagination.itemsPerPage
    );
    this.loading = false;
  }

  protected async mounted() {
    this.loading = true;
    this.setPageNav({
      title: "reconciliation.sales.possible_duplicates",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    await this.loadDuplicates();
    this.loading = false;
  }

  protected showLoadingWindow(): void {
    this.$modals
      .load(LoadingWindowComponent, {
        closable: false,
        size: "fit",
        positionY: "center",
        positionX: "center",
        backdrop: true
      })
      .catch(() => {
        // nothing to do
      });
  }

  protected updateLoadingWindow(event: {
    errorList: Array<{ message: string }>;
    className?: string;
  }): void {
    EventBus.$emit("mtrcLoadingEvent", {
      show: true,
      ...event
    });
  }

  protected hideLoadingWindow(): void {
    EventBus.$emit("mtrcLoadingEvent", {
      show: false
    });
  }

  private async loadDuplicates(sort: string | null = null) {
    const saleItem = {
      ...this.data.order,
      order: {
        ...this.data.order.order,
        items:
          this.data.order.order.items &&
          this.data.order.order.items.map(item => ({
            ...item,
            line_item_formatted: item.line_item_discounts.toFixed(2),
            price_formatted: item.price_after_discounts.toFixed(2),
            quantity_formatted: `${item.quantity}${item.unit}`
          }))
      }
    };
    this.sale = [saleItem];

    this.possibleDuplicates = this.data.duplicates;
    this.duplicatesIDs = this.possibleDuplicates.map(elem => elem.id);
    this.items = this.possibleDuplicates.slice(
      0,
      this.tablePagination.itemsPerPage
    );
    this.pagination =
      this.possibleDuplicates.length > 10
        ? {
            ...this.tablePagination,
            totalItems: this.possibleDuplicates.length
          }
        : null;
  }
}
