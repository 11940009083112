import { actions } from "@/vuex/modules/orderFulfillment/orderFulfillment.actions";
import { getters } from "@/vuex/modules/orderFulfillment/orderFulfillment.getters";
import { mutations } from "@/vuex/modules/orderFulfillment/orderFulfillment.mutations";
import { state } from "@/vuex/modules/orderFulfillment/orderFulfillment.state";
import { OrderFulfillmentState } from "@/vuex/modules/orderFulfillment/orderFulfillment.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";

const namespaced: boolean = true;

export const OrderFulfillmentModule: Module<
  OrderFulfillmentState,
  RootState
> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
