import { OrderFulfillmentModule } from "@/vuex/modules/orderFulfillment/orderFulfillment.index";
import { Component, Vue } from "vue-property-decorator";

@Component({
  render: c => c("router-view")
})
export default class OrderFulfillmentComponent extends Vue {
  protected created() {
    this.$store.registerModule(
      "OrderFulfillmentModule",
      OrderFulfillmentModule
    );
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("OrderFulfillmentModule");
  }
}
