import { MutationTree } from "vuex";
import { OrderFulfillmentState } from "./orderFulfillment.types";

type OrderFulfillmentMutationTree = MutationTree<OrderFulfillmentState>;

export const mutations: OrderFulfillmentMutationTree = {
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
  setDetails(state, details) {
    state.details = details;
  }
};
