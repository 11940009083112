import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { OrderFulfillmentState } from "./orderFulfillment.types";

type OrderGetter = GetterTree<OrderFulfillmentState, RootState>;

export const getters: OrderGetter = {
  loading(context) {
    return context.loading;
  },
  details(context) {
    return context.details;
  },
  pagination(context) {
    return context.pagination;
  }
};
