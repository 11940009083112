import { messagesService } from "@/services/messages.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";
import { OrderFulfillmentState } from "./orderFulfillment.types";

type OrderActionContext = ActionContext<OrderFulfillmentState, RootState>;
type OrderActionTree = ActionTree<OrderFulfillmentState, RootState>;

export const actions: OrderActionTree = {
  async setDetails(context: OrderActionContext, id: number) {
    context.commit("setLoading", true);
    try {
      const response = await orderFulfillmentService.findById(id);
      context.commit("setDetails", response);
    } catch (e) {
      context.commit("setDetails", null);
      messagesService.renderErrorMessage(e);
    }
  }
};
