import { EventBus } from "@/event-bus";
import { ActionSubheader } from "helix-vue-components";
import { messagesService } from "./messages.service";
import { traceabilityService } from "./traceability.service";

class ReconciliationService {
  public getGeneralActions(
    saveReconciliation: () => void,
    cancel: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-check",
        id: "btn_add_customer",
        action: saveReconciliation
      },
      {
        icon: "fal fa-times",
        action: cancel
      }
    ];
  }
}

export const reconciliationService: ReconciliationService = new ReconciliationService();
